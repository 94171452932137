import React from "react";
import { Card, Row } from "react-bootstrap";
import WorkIcon from "@material-ui/icons/Work";
import "./offerCard.scss";
const OfferCard = ({ offerData }) => (
  <div className="job-search-container">
    <a
      href={`/offres/${offerData.categorie}/${offerData.titrePage}-${offerData.numeroInterne}`}
    >
      <Card className="textAlignCenter job-search-card">
        <Card.Body>
          <div className="inner">
            <Card.Title>{offerData.titreOffre}</Card.Title>
            <Card.Text>
              <Row className="job-footer-infos">
                <span>
                  <WorkIcon />
                  {offerData.villeEmployeur}
                </span>
              </Row>
            </Card.Text>
          </div>
        </Card.Body>
      </Card>
    </a>
  </div>
);

OfferCard.propTypes = {};

OfferCard.defaultProps = {};

export default OfferCard;
